.root {
  @media only screen and (min-width: 800px) {
    background-image: url("../assets/backgrounds/legal-bg.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 400px;
    background-position: left center;
  }
  height: 100%;
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-inline: 16px;
    @media only screen and (min-width: 1040px) {
      padding: 0 25%;
    }
    .main-title {
      text-align: center;
      font-size: calc(var(--size) * 3);
      line-height: 150%;
      font-weight: 600;
      margin-bottom: 32px;
    }
    .title {
      text-align: left;
      font-size: calc(var(--size) * 2);
      line-height: 115%;
      font-weight: 600;
      margin-top: 16px;
    }

    .text {
      text-align: left;
      color: #c0c2cd;
      font-size: max(calc(var(--size) * 0.85), 16px);
      line-height: 150%;
      font-weight: 300;
      margin: 8px 0;

      &-bold {
        font-weight: 600;
      }
      &-italic {
        font-style: italic;
      }
    }
    a {
      color: #fafafa;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}
